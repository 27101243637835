import { dev } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry } from '@sentry/sveltekit';

Sentry.init({
  dsn: !dev
    ? 'https://659533e580244000865e65dffc1f5bd7@o1297718.ingest.sentry.io/4504593585864704'
    : '',
  tracesSampleRate: 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.05,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  // replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [
    // new posthog.SentryIntegration(posthog, 'betwatch-tr', 4504593585864704)
  ]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
// only handle errors in production
export const handleError = handleErrorWithSentry();
