import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [0];

export const dictionary = {
		"/": [10],
		"/admin": [~24,[6]],
		"/admin/reset-market": [~25,[6]],
		"/api/auth": [26],
		"/app": [27,[7]],
		"/app/account": [28,[7]],
		"/app/account/api": [29,[7]],
		"/app/profile": [30,[7]],
		"/app/racing": [31,[7,8]],
		"/app/racing/id/[id]": [~33,[7,8]],
		"/app/racing/search": [34,[7,8]],
		"/app/racing/[date]/[type]/[track]/[number]": [32,[7,8,9]],
		"/(auth)/auth/redirect": [15,[2]],
		"/(billing)/billing": [~16],
		"/(billing)/billing/success/[plan]": [~17],
		"/(auth)/(login)/logging-in": [11,[4]],
		"/(auth)/(login)/login": [12,[2,3]],
		"/(auth)/(login)/login/social": [13,[2,3]],
		"/(content)/odds-comparison": [~19,[5]],
		"/(content)/odds-comparison/[type]/[location]/[track]": [~20,[5]],
		"/(content)/privacy-policy": [21,[5]],
		"/(content)/racing-odds-comparison": [~22,[5]],
		"/(auth)/(login)/register": [14,[2,3]],
		"/(billing)/subscribe/[plan]": [~18],
		"/(content)/terms": [23,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';